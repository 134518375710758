import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Response } from '@app/core/interfaces/response';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

/** @deprecated - consider using version from outshared-lib */
@Injectable({
    providedIn: 'root',
})
export class RequestService {

    constructor(
        private http: HttpClient,
        private loader: LoadingBarService,
    ) { }

    post(url: string, payload?: any, silent: boolean = false): Observable<Response> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            withCredentials: true,
        };

        if (silent === false) {
            this.startLoading();
        }

        return this.http.post('/json/' + url, payload, httpOptions).pipe(
            map(this.extractData),
            catchError((error: any) => {
                return this.handleError(error);
            }),
            finalize(() => this.completeLoading()));
    }

    get(url: string, params?: string): Observable<Response> {
        const options = params ? { params: new HttpParams().set('search', params) } : {};

        return this.http.get(url, options).pipe(
            map(this.extractGetData),
            catchError((error: any) => {
                return this.handleError(error);
            }),
            finalize(() => this.completeLoading()));
    }

    download(url: string, payload?: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        this.startLoading();

        return this.http.post('/documenten-json/' + url, payload, httpOptions).pipe(
            map(this.extractData),
            catchError((error: any) => {
                return this.handleError(error);
            }),
            finalize(() => this.completeLoading()));
    }

    private extractData(body: any): Response {
        if (body.resultaat === undefined && body.result !== false && body.result !== null) {
            body.resultaat = body.result;
        }
        if (body.resultaat === false || body.resultaat === null) {
            throw body.meldingen || body.notifications;
        }

        return {
            data: body.resultaat ? body.resultaat : body.result ? body.result : false,
            messages: body.meldingen ? body.meldingen : null,
        };
    }

    private extractGetData(body: any): Response {

        if (body.value === false || body.value === null) {
            throw body.meldingen || body.notifications;
        }

        return {
            data: body.value ? body.value[0] : false,
            messages: body.meldingen ? body.meldingen : null,
        };
    }

    private handleError(response: HttpErrorResponse): Observable<any> {
        if (response === null) {
            if (isDevMode()) {
                console.error('body.resultaat or body.result is missing, inform backend devs (should be true || false)');
            }
            return observableThrowError('');
        }

        if (!response.status) {
            return observableThrowError(response);
        }

        if (response.status !== 200) {
            const body = response.error;
            return observableThrowError(body.meldingen);
        }

        if (isDevMode()) {
            console.error(response); // log to console instead
        }

        return observableThrowError(response);
    }

    private startLoading(): void {
        this.loader.start();
    }

    private completeLoading(): void {
        this.loader.complete();
    }
}
